import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { graphql } from 'gatsby';
import { isObject } from 'lodash';
import React, { useEffect, useState } from 'react';
import AboutUsLanding from '../components/AboutUsLanding';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
import DialogPopupContext from '../utils/dialogpopup-context';
//import LoginPage from '../pages/login';

export default function AboutUsPage({location, pageContext, data }) {
  console.log("aboutus",data);
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };
  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };
  const [open, setOpen] = useState(false);
  const openPopupClick = () => {
    setOpen(true);
  }
  const closePopupClick = () => {
    setOpen(false);
  }


  return (
    <>
      <DialogPopupContext.Provider value={{ status: open, openPopup: openPopupClick, closePopup: closePopupClick}}>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulAboutUs?.seoEntry}
          siteurl={pageContext?.slug}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url+'?fm=webp&q=100'}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
          videoSEOSchema ={data?.contentfulAboutUs?.storyhistorySection?.videoComponent?.videoComponent}
        />

        <AboutUsLanding
          data={data?.contentfulAboutUs}
          pressData={data?.allContentfulPressRoom?.nodes}
          basePath={pageContext.basePath}
          backgroundImg={data?.contentfulComponentBackgroundImages}
        />
      </Layout>
      </DialogPopupContext.Provider>
    </>
  );
}

export const query = graphql`
  query aboutUsQuery($contentful_id: String, $locale: String) {
    contentfulComponentBackgroundImages {
      gridBrightBlueBackgroundImage {
        file {
          url
        }
      }
      donutSliderBackgroundImage {
        file {
          url
        }
      }
      headerDonutImage {
        file {
          url
        }
      }
      hoverLeftArrowImage {
        file {
          url
        }
      }
      hoverRightArrowImage {
        file {
          url
        }
      }
      leftArrowImage {
        file {
          url
        }
      }
      rightArrowImage {
        file {
          url
        }
      }
      capsulesBackgroundImage{
        file{
          url
        }
      }
      headerDotMatrixImage{
        file{
          url
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      leftDonutBackgroundImage {
        file {
          url
        }
      }
      emailIcon {
        description
        file {
          url
        }
      }
    }
    contentfulAboutUs(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      ourRichHeritageAutoRotate 
      employeeAwardsSectionAutoRotate 
      employeeTestimonialsAutoRotate
      ourWinningBehaviorsBackgroundImage {
        title
        file {
          url
        }
      }
      winningBehaviorsTitleShortDescriptionLogo {
        name
        entryTitle
        description {
          raw
        }
        image {
          title
          description
          file {
            url
          }
          title
        }
      }
      winningBehaviorsLongDescription {
        raw
      }
      ourWinningBehaviorsButtonLabel
      ourWinningBehaviorsUrl
      entryTitle
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          title
          file {
            url
          }
        }
      }
      aboutUsLeftDonutBackgroundImage {
        title
        file {
          url
        }
      }
      rightDonutBackgroundImage {
        title
        file {
          url
        }
      }
      capsulesBackgroundImage {
        title
        file {
          url
        }
      }
      capsuleLeftBackgroundImage {
        title
        file {
          url
        }
      }
      ourShiningJewelsHeader
      ourShiningJewelsSubHeader
      ourShiningJewelsSection {
        ...compPerson
      }
      viewMore {
        slug
        label
      }
      pressReleasesTitle
      viewPressRoom {
        label
        link {
          ... on ContentfulInternalLink {
            slug
          }
          ... on ContentfulWebLink {
            url
          }
        }
      }
      circularSectionTitle
      ourRichHeritageArrowImage {
        title
        description
        file {
          url
        }
      }
      ourRichHeritageTitle
      ourRichHeritageTileSection {
        dateOfMilestone
        description {
          raw
        }
      }
      circularSectionDescription {
        raw
      }
      circularSectionBackgroundImage {
        title
        file {
          url
          fileName
        }
      }
      heroImage {
        title
        description
        file {
          url
          fileName
        }
      }
      dotMatrixBackgroundImage {
        title
        file {
          url
          fileName
        }
      }
      smallDotMatrixImage {
          title
          file {
            url
            fileName
          }
      }
      
      aboutUsStorySectionDescription
      aboutUsStorySectionTopic
      capsulesBackgroundImage {
        title
        file {
          fileName
          url
        }
      }
      storyhistorySection {
        header
        subHeader
        isVideo
        videoComponent {
          ...componentVideo
        }
        shortDescription {
          raw
        }
        image {
          title
          description
          file {
            url
          }
        }
        ctaButton {
          buttonLabel
          link {
            link {
              ... on ContentfulWebLink {
                id
                url
              }
            }
          }
        }
      }
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      employeeAwardsSection {
        title
        description
        file {
          url
        }
      }

      employeeTestimonials {
        ...compAuthor
      }
      aboutUsJoinOurTeam {
        entryTitle
        header
        subHeader
        shortDescription {
          raw
        }
        mainImage {
          description
          title
          file {
            fileName
            url
          }
        }
        additionalImage {
          description
          title
          file {
            fileName
            url
          }
        }
        ctaOrPardotForm
        ctaButton {
          displayPrimaryCta
          labelForPrimaryCta
          primaryCtaShouldBeDisplayedAs
          primaryCtaShouldOpenInTheSameTab
          callToAction {
            ... on ContentfulInternalLink {
              id
              slug
              pageName
            }
          }
        }
      }
      pardotForm {
        id
        contentful_id
        entryTitle
        header
        subHeader {
          raw
        }
        iFrameLink
        closeIcon {
          description
          file {
            url
          }
        }
      }
    }
    allContentfulPressRoom(filter: { node_locale: { eq: $locale } }) {
      nodes {
        id
        entryTitle
        contentful_id
        slug
        pressReleaseType
        pressReleaseConent {
          ... on ContentfulPressReleaseLongContent {
            id
            entryTitle
            contentful_id
            pressContentTitle
            dateOfPublication(locale: "US")
            description {
              raw
            }
          }
          ... on ContentfulPressReleaseShortContent {
            id
            entryTitle
            contentful_id
            pressReleaseTitle
            dateOfPublication(locale: "US")
            description {
              raw
            }
          }
        }
      }
    }
  }
  fragment componentVideo on ContentfulCustomVideo {
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    entryTitle
    videoComponent {
      entryTitle
      videoType
      videoId
      videoTitle
      dateOfPublishing(formatString: "YYYY-MM-DD")
      seoEntry {
        metaDescription {
          metaDescription
        }
      }
    }
  }

  fragment compAuthor on ContentfulCompAuthor {
    entryTitle
    authorImage {
      title
      description
      file {
        url
      }
    }
    authorDesignation
    authorDescription {
      raw
    }
    authorName
    postedBy
    secondaryImage {
      title
      description
      file {
        url
      }
    }
    sys {
      contentType {
        sys {
          id
          type
        }
      }
    }
  }

  fragment compPerson on ContentfulComponentPerson {
    entryTitle
    name
    image {
      title
      description
      file {
        url
      }
    }
    personalQuote {
      personalQuote
    }
    bioWriteup {
      raw
    }
    linkedInImage {
      file {
        url
      }
      description
    }
    emailId
    linkedinId
    dotMatrixBackgroundImage {
      file {
        url
      }
    }
    rightDonutBackgroundImage {
      file {
        url
      }
    }
    closeIcon {
      file {
        url
      }
    }
    designation
    region {
      raw
    }
    sys {
      contentType {
        sys {
          id
          type
        }
      }
    }
  }
`;
